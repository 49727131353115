// src/components/game/GameBoard.jsx
import React from 'react';
import WordChain from './WordChain';
import LetterGrid from './LetterGrid';
import VirtualKeyboard from './VirtualKeyboard';

/**
 * GameBoard component
 * Handles the display of the main game interaction elements
 */
function GameBoard({
    currentLevel,
    userInput,
    handleKeyPress,
    handleBackspace,
    handleSubmit,
    isGameOver,
    currentLevelIndex
}) {
    // Early return if level is complete or game is over
    const isLevelActive = !isGameOver && !currentLevel.isComplete;

    return (
        <div className="game-board">
            {/* Display word chain if there are guesses */}
            {currentLevel.guesses.length > 0 && (
                <WordChain
                    words={currentLevel.guesses}
                    targetWord={currentLevel.word}
                />
            )}

            {/* Only show input if level is active */}
            {isLevelActive && (
                <div className="input-form">
                    <LetterGrid
                        targetWord={currentLevel.word}
                        guesses={currentLevel.guesses}
                        userInput={userInput}
                    />
                    <VirtualKeyboard
                        onKeyPress={handleKeyPress}
                        onSubmit={handleSubmit}
                        onBackspace={handleBackspace}
                        guesses={currentLevel.guesses}
                        targetWord={currentLevel.word}
                        currentLevel={currentLevelIndex}
                    />
                </div>
            )}
        </div>
    );
}

export default GameBoard;
