// src/components/Admin.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3002';

const Admin = () => {
    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refreshTime, setRefreshTime] = useState(new Date());
    console.log('admin in');

    const fetchAdminStats = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_BASE_URL}/api/admin/stats`);
            setStats(response.data);
            setError(null);
            setRefreshTime(new Date());
        } catch (err) {
            console.error('Failed to fetch admin stats:', err);
            setError('Failed to fetch admin statistics. Access might be restricted.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAdminStats();

        // Refresh stats every minute
        const interval = setInterval(fetchAdminStats, 60000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="min-h-screen bg-primary-800 text-gray-100 p-4 md:p-8">
            <header className="mb-8">
                <h1 className="text-3xl font-bold text-center mb-2">WordCrown Admin</h1>
                <p className="text-center text-gray-400">
                    {loading ? 'Loading...' : `Last updated: ${refreshTime.toLocaleTimeString()}`}
                </p>
            </header>

            {error && (
                <div className="bg-red-600/30 border border-red-600 text-white p-4 rounded-lg mb-6">
                    <p>{error}</p>
                </div>
            )}

            {!loading && stats && (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
                        <StatCard
                            title="Players Today"
                            value={stats.totalPlayers}
                            icon="👥"
                        />
                        <StatCard
                            title="Winners Today"
                            value={stats.totalWinners}
                            icon="🏆"
                        />
                        <StatCard
                            title="Win Rate"
                            value={`${stats.winRate}%`}
                            icon="📊"
                        />
                        <StatCard
                            title="Avg Winner Guesses"
                            value={stats.avgWinnerGuesses}
                            icon="🎯"
                        />
                    </div>

                    <div className="bg-primary-600/30 rounded-lg p-4 mb-8">
                        <h2 className="text-xl font-bold mb-4">Recent Completions</h2>
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-transparent">
                                <thead>
                                    <tr className="border-b border-gray-700">
                                        <th className="px-4 py-3 text-left">User ID</th>
                                        <th className="px-4 py-3 text-left">Level</th>
                                        <th className="px-4 py-3 text-left">Guesses</th>
                                        <th className="px-4 py-3 text-left">Status</th>
                                        <th className="px-4 py-3 text-left">Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stats.recentCompletions.map((completion) => (
                                        <tr key={completion._id} className="border-b border-gray-700">
                                            <td className="px-4 py-3">{completion.userId.substring(0, 8)}...</td>
                                            <td className="px-4 py-3">{completion.levelReached}</td>
                                            <td className="px-4 py-3">{completion.totalGuesses}</td>
                                            <td className="px-4 py-3">
                                                <span
                                                    className={`inline-block px-2 py-1 rounded ${completion.completed
                                                            ? "bg-green-800/30 text-green-400"
                                                            : "bg-red-800/30 text-red-400"
                                                        }`}
                                                >
                                                    {completion.completed ? "Won" : "Lost"}
                                                </span>
                                            </td>
                                            <td className="px-4 py-3">{new Date(completion.createdAt).toLocaleTimeString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <button
                            onClick={fetchAdminStats}
                            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded transition"
                        >
                            Refresh Data
                        </button>
                    </div>
                </>
            )}

            {loading && !error && (
                <div className="flex justify-center items-center h-64">
                    <div className="spinner"></div>
                </div>
            )}
        </div>
    );
};

// Stat Card Component
const StatCard = ({ title, value, icon }) => (
    <div className="bg-primary-600/30 p-4 rounded-lg">
        <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-semibold text-gray-300">{title}</h3>
            <span className="text-2xl">{icon}</span>
        </div>
        <p className="text-3xl font-bold">{value}</p>
    </div>
);

export default Admin;
