// src/config/puzzles.js
// Collection of all puzzles organized by date
import timeService from '../services/timeService';

export const PUZZLES = [
    {
        date: "03/27/2025",
        category: "Politics",
        levels: [
            { word: 'COURT' },
            { word: 'ELECT' },
            { word: 'PARTY' },
            { word: 'HOUSE' },
            { word: 'FRINGE' },
            { word: 'AGENDA' },
            { word: 'CABINET' },
            { word: 'CAMPAIGN' }
        ]
    },
    {
        date: "03/28/2025",
        category: "Breakfast foods",
        levels: [
            { word: 'MAPLE' },
            { word: 'BACON' },
            { word: 'FRUIT' },
            { word: 'CEREAL' },
            { word: 'YOGURT' },
            { word: 'WAFFLE' },
            { word: 'GRANOLA' },
            { word: 'SMOOTHIE' }
        ]
    },
    {
        date: "03/29/2025",
        category: "Modes of transportation",
        levels: [
            { word: 'CANOE' },
            { word: 'TRAIN' },
            { word: 'SEDAN' },
            { word: 'SUBWAY' },
            { word: 'ROCKET' },
            { word: 'BICYCLE' },
            { word: 'MINIVAN' },
            { word: 'GONDOLA' }
        ]
    },
    {
        date: "03/30/2025",
        category: "Things you throw away",
        levels: [
            { word: 'WASTE' },
            { word: 'TRASH' },
            { word: 'SCRAP' },
            { word: 'DIAPER' },
            { word: 'TISSUE' },
            { word: 'NAPKIN' },
            { word: 'WRAPPER' },
            { word: 'BANDAGE' }
        ]
    },
    {
        date: "03/31/2025",
        category: "Things in a hospital",
        levels: [
            { word: 'CHART' },
            { word: 'BLOOD' },
            { word: 'NURSE' },
            { word: 'DEATH' },
            { word: 'BIOPSY' },
            { word: 'GURNEY' },
            { word: 'SCALPEL' },
            { word: 'CATHETER' }
        ]
    },
    {
        date: "04/01/2025",
        category: "Things at a wedding",
        levels: [
            { word: 'TOAST' },
            { word: 'GUEST' },
            { word: 'BRIDE' },
            { word: 'VALET' },
            { word: 'PRIEST' },
            { word: 'GARTER' },
            { word: 'BOUQUET' },
            { word: 'CEREMONY' }
        ]
    },
    {
        date: "04/02/2025",
        category: "Names?",
        levels: [
            { word: 'AMBER' },
            { word: 'DAISY' },
            { word: 'OLIVE' },
            { word: 'CHASE' },
            { word: 'PEARL' },
            { word: 'HUNTER' },
            { word: 'WILLOW' },
            { word: 'HARMONY' }
        ]
    },
    {
        date: "04/03/2025",
        category: "Fears",
        levels: [
            { word: 'GHOST' },
            { word: 'SNAKE' },
            { word: 'DEATH' },
            { word: 'SPIDER' },
            { word: 'CANCER' },
            { word: 'INSECT' },
            { word: 'THUNDER' },
            { word: 'FAILURE' }
        ]
    },
    // {
    //     date: "04/04/2025",
    //     category: "",
    //     levels: [
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' }
    //     ]
    // },
    // {
    //     date: "04/05/2025",
    //     category: "",
    //     levels: [
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' }
    //     ]
    // },
    // {
    //     date: "04/06/2025",
    //     category: "",
    //     levels: [
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' }
    //     ]
    // },
    // {
    //     date: "04/07/2025",
    //     category: "",
    //     levels: [
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' },
    //         { word: '' }
    //     ]
    // },
];

// Helper function to get puzzle for a specific date
export function getPuzzleForDate(date) {
    // Convert the input date (or current date) to Eastern Time
    const targetDate = date || new Date();
    
    // Use timeService for Eastern Time formatting
    const formattedTargetDate = timeService.getEasternDateForPuzzle(targetDate);
    
    // Try to find an exact match for today's date (in Eastern Time)
    let puzzle = PUZZLES.find(puzzle => puzzle.date === formattedTargetDate);
    
    // If no exact match, find the most recent puzzle before today
    if (!puzzle) {
        // Convert all puzzle dates to Date objects for comparison
        const puzzleDates = PUZZLES.map(p => ({
            puzzle: p,
            date: new Date(p.date)
        }));
        
        // Get current date in Eastern Time for comparison
        const etDateParts = formattedTargetDate.split('/');
        const etDate = new Date(
            parseInt(etDateParts[2]), // year
            parseInt(etDateParts[0]) - 1, // month (0-indexed)
            parseInt(etDateParts[1]) // day
        );
        
        // Sort by date, most recent first
        puzzleDates.sort((a, b) => b.date - a.date);
        
        // Find the most recent puzzle before target date
        const mostRecent = puzzleDates.find(p => p.date <= etDate);
        
        // Use the most recent puzzle if found, otherwise use the first puzzle
        puzzle = mostRecent ? mostRecent.puzzle : PUZZLES[0];
    }
    
    return puzzle;
}
