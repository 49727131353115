// src/services/gameService.js
import { GAME_CONFIG } from '../config';
import { statsService } from './statsService';
import apiService from './apiService';
import timeService from './timeService';
import { storageService } from './storageService';

// Storage key constants
const STORAGE_KEY = 'wordcrown_game_state';
const STREAK_KEY = 'wordcrown_streak';
const USER_ID_KEY = 'wordcrown_user_id';
const HAS_PLAYED_KEY = 'wordcrown_has_played';

class GameService {
    constructor() {
        this.apiEnabled = apiService.isEnabled;
    }

    // Test API connection - useful for debugging
    async testApiConnection() {
        return await apiService.testConnection();
    }

    // Test recording a completion
    async testRecordCompletion() {
        return await apiService.testRecordCompletion();
    }

    getUserId() {
        return storageService.getUserId();
    }

    getDailyWords() {
        // Get today's puzzle using the date-based system
        const todaysPuzzle = GAME_CONFIG.getPuzzleForDate();

        if (!todaysPuzzle || !todaysPuzzle.levels) {
            console.error('No puzzle found for today');
            // Fallback to the first puzzle if no match
            return GAME_CONFIG.puzzles[0].levels;
        }

        // Add length property if it doesn't exist (for backward compatibility)
        const levelsWithLength = todaysPuzzle.levels.map(level => ({
            ...level,
            length: level.word.length
        }));

        return levelsWithLength;
    }

    // In gameService.js, update the getDailyState method:

    getDailyState() {
        // Get current date in Eastern Time
        const now = new Date();
        const today = timeService.getEasternTimeDate(now);

        // Get today's puzzle explicitly for the current date
        const todaysPuzzle = GAME_CONFIG.getPuzzleForDate(now);
        const todaysFirstWord = todaysPuzzle.levels[0].word;

        // Log for debugging
        // console.log('Daily state check:', {
        //     date: today,
        //     puzzle: todaysPuzzle.date,
        //     firstWord: todaysFirstWord
        // });

        // Use storageService to get the game state
        const savedState = storageService.getGameState(STORAGE_KEY);
        const streakData = this.getStreakData();

        if (savedState) {
            // Don't need to JSON.parse savedState because storageService.getGameState 
            // already returns a parsed object
            const state = savedState;

            // Only reuse state if BOTH the date is today AND the puzzle matches today's puzzle
            if (state.date === today && state.levels[0].word === todaysFirstWord) {
                // console.log('Reusing existing state - same day and puzzle');
                return {
                    ...state,
                    streak: streakData.streak
                };
            } else {
                // console.log('Creating new state - new day or different puzzle detected', {
                //     savedDate: state.date,
                //     todayDate: today,
                //     savedWord: state.levels[0].word,
                //     todayWord: todaysFirstWord
                // });
            }
        } else {
            console.log('No saved state found, creating new state');
        }

        // Create a fresh state with today's puzzle
        const newState = {
            date: today,
            currentLevel: 0,
            totalGuesses: 0,
            streak: streakData.streak,
            longestStreak: 0,
            isGameOver: false,
            category: todaysPuzzle.category,
            // Important: Use todaysPuzzle.levels directly to ensure latest puzzle data
            levels: todaysPuzzle.levels.map((level, index) => ({
                ...level,
                guesses: [],
                missedAttempts: 0,
                isComplete: false,
                isLocked: index > 0
            }))
        };

        storageService.saveGameState(STORAGE_KEY, newState);
        return newState;
    }

    // Any guess that isn't the correct word counts as a miss
    countAsMiss(guess, targetWord) {
        return guess !== targetWord;
    }

    async saveGameState(levelIndex, guesses, isComplete, countMiss = false) {
        try {
            const currentState = storageService.getGameState(STORAGE_KEY);

            // Guard against invalid state
            if (!currentState || !currentState.levels) {
                console.error('Invalid current state:', currentState);
                throw new Error('Invalid game state');
            }

            const totalGuesses = this.calculateTotalGuesses(currentState, levelIndex, guesses);

            const newState = {
                ...currentState,
                totalGuesses,
                levels: [...currentState.levels]
            };

            // Update the current level
            const currentLevel = newState.levels[levelIndex];
            currentLevel.guesses = guesses;
            currentLevel.isComplete = isComplete;

            // Update missed attempts for the current level if needed
            if (countMiss) {
                currentLevel.missedAttempts = (currentLevel.missedAttempts || 0) + 1;
            }

            // Check if current level is failed (too many missed attempts)
            const isLevelFailed = currentLevel.missedAttempts >= GAME_CONFIG.maxMissedAttempts;

            // Game is over if current level failed or all levels complete
            newState.isGameOver = isLevelFailed ||
                (isComplete && levelIndex === newState.levels.length - 1);

            // If this level is complete, unlock the next level
            if (isComplete && levelIndex < newState.levels.length - 1) {
                newState.levels[levelIndex + 1].isLocked = false;
                newState.currentLevel = levelIndex + 1;
            }

            // Handle game completion or failure
            if (newState.isGameOver) {
                const completed = isComplete && levelIndex === currentState.levels.length - 1;
                const userId = this.getUserId();
                const levelReached = levelIndex + 1;

                // API interactions are wrapped in try/catch to prevent Promise issues
                if (this.apiEnabled) {
                    try {
                        const apiResponse = await apiService.recordCompletion(
                            userId,
                            newState.totalGuesses,
                            levelReached,
                            completed
                        );

                        // Use stats from API response
                        newState.dailyStats = {
                            totalWinners: apiResponse.totalWinners,
                            averageGuesses: apiResponse.averageGuesses,
                            averageLevel: apiResponse.averageLevel ||
                                Math.round(apiResponse.totalLevelReached / apiResponse.completions)
                        };

                        // Set current and longest streak from API
                        newState.streak = apiResponse.currentStreak || 0;
                        newState.longestStreak = apiResponse.longestStreak || 0;

                        console.log('API recorded completion successfully:', apiResponse);
                    } catch (apiError) {
                        console.error('Failed to record completion with API:', apiError);

                        // Fallback to local stats on API failure
                        const updatedStats = statsService.recordCompletion(
                            userId,
                            newState.totalGuesses,
                            levelReached,
                            completed
                        );

                        newState.dailyStats = {
                            totalWinners: updatedStats.winners.length,
                            averageGuesses: Math.round(updatedStats.totalGuesses / updatedStats.completions),
                            averageLevel: Math.round(updatedStats.totalLevelReached / updatedStats.completions)
                        };

                        // Fallback to local streak calculation
                        const newStreak = this.updateStreak(completed);
                        newState.streak = newStreak;
                    }
                } else {
                    // Use local stats storage
                    const updatedStats = statsService.recordCompletion(
                        userId,
                        newState.totalGuesses,
                        levelReached,
                        completed
                    );

                    newState.dailyStats = {
                        totalWinners: updatedStats.winners.length,
                        averageGuesses: Math.round(updatedStats.totalGuesses / updatedStats.completions),
                        averageLevel: Math.round(updatedStats.totalLevelReached / updatedStats.completions)
                    };

                    // Use local streak calculation
                    const newStreak = this.updateStreak(completed);
                    newState.streak = newStreak;
                }
            }

            // Save state to local storage
            storageService.saveGameState(STORAGE_KEY, newState);

            // Important: Ensure we're returning a proper object, not a Promise
            return newState;
        } catch (error) {
            console.error('SaveGameState error:', error);
            throw error;
        }
    }

    // Fetch current stats from API
    async fetchStats() {
        if (!this.apiEnabled) {
            // Use local stats if API is disabled
            return statsService.getDailyStats();
        }

        try {
            return await apiService.getDailyStats();
        } catch (error) {
            console.error('Failed to fetch stats from API:', error);
            // Fallback to local stats
            return statsService.getDailyStats();
        }
    }

    // Fetch user stats from API
    async fetchUserStats() {
        if (!this.apiEnabled) {
            // Use local stats if API is disabled
            return {
                currentStreak: this.getStreakData().streak,
                longestStreak: 0
            };
        }

        try {
            const userId = this.getUserId();
            return await apiService.getUserStats(userId);
        } catch (error) {
            console.error('Failed to fetch user stats from API:', error);
            // Fallback to local stats
            return {
                currentStreak: this.getStreakData().streak,
                longestStreak: 0
            };
        }
    }

    calculateTotalGuesses(state, currentLevelIndex, currentGuesses) {
        let total = 0;
        state.levels.forEach((level, index) => {
            if (index === currentLevelIndex) {
                total += currentGuesses.length;
            } else {
                total += level.guesses.length;
            }
        });
        return total;
    }

    getStreakData() {
        const streakData = storageService.getItem(STREAK_KEY);
        if (!streakData) {
            return { streak: 0, lastComplete: null };
        }
        return JSON.parse(streakData);
    }

    updateStreak(completed) {
        const today = timeService.getEasternTimeDate();
        const streakData = this.getStreakData();

        if (completed) {
            const yesterdayString = timeService.getEasternYesterday();

            if (streakData.lastComplete === yesterdayString) {
                streakData.streak += 1;
            } else if (streakData.lastComplete !== today) {
                streakData.streak = 1;
            }
            streakData.lastComplete = today;
        } else {
            if (streakData.lastComplete) {
                const daysSinceComplete = timeService.daysBetweenInEasternTime(
                    streakData.lastComplete,
                    new Date()
                );

                if (daysSinceComplete > 1) {
                    streakData.streak = 0;
                }
            }
        }

        storageService.setItem(STREAK_KEY, JSON.stringify(streakData));
        return streakData.streak;
    }

    hasPlayedBefore() {
        return storageService.getItem(HAS_PLAYED_KEY) === 'true';
    }

    markAsPlayed() {
        storageService.setItem(HAS_PLAYED_KEY, 'true');
    }

    getModeConfig() {
        return GAME_CONFIG.mode;
    }

    // Enable or disable API usage
    setApiEnabled(enabled) {
        this.apiEnabled = apiService.setEnabled(enabled);
        console.log(`API ${enabled ? 'enabled' : 'disabled'}`);
        return this.apiEnabled;
    }
}

export const gameService = new GameService();
