// src/components/modals/PrivacyPolicyModal.jsx
import React from 'react';
import BaseModal from './BaseModal';

/**
 * PrivacyPolicyModal - Displays the game's privacy policy using the BaseModal component
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to call when modal is closed
 * @returns {React.ReactElement|null}
 */
const PrivacyPolicyModal = ({ isOpen, onClose }) => {
    // Last updated date - should be kept in sync with the actual policy
    const lastUpdated = "March 30, 2025";

    // Modal content
    const modalContent = (
        <div className="privacy-policy">
            <div className="privacy-section">
                <p className="privacy-content">
                    <strong>Last Updated:</strong> {lastUpdated}
                </p>
            </div>
            
            <div className="privacy-section">
                <h3 className="privacy-title">Introduction</h3>
                <p className="privacy-content">
                    Welcome to WordCrown. We respect your privacy and are committed to protecting any data that we collect. 
                    This Privacy Policy explains what information we collect, how we use it, and what rights you have in relation to it.
                </p>
            </div>
            
            <div className="privacy-section">
                <h3 className="privacy-title">Information We Collect</h3>
                <p className="privacy-content">
                    WordCrown is designed with privacy in mind. We collect minimal data necessary to provide our service:
                </p>
                <ul className="privacy-list">
                    <li><strong>Session Data:</strong> We generate and store a random, anonymous user ID for your session. 
                    This ID does not personally identify you and is used solely to track game progress and statistics.</li>
                    <li><strong>Game Progress:</strong> We store your game progress, including levels completed, words guessed, and performance statistics.</li>
                    <li><strong>Gameplay Statistics:</strong> We collect aggregated, anonymous statistics about game usage, such as average scores and completion rates.</li>
                </ul>
            </div>
            
            <div className="privacy-section">
                <h3 className="privacy-title">How We Use Information</h3>
                <p className="privacy-content">
                    The information we collect is used exclusively for:
                </p>
                <ul className="privacy-list">
                    <li>Saving your game progress</li>
                    <li>Calculating streak data</li>
                    <li>Generating anonymous game statistics</li>
                    <li>Improving the game experience</li>
                </ul>
            </div>
            
            <div className="privacy-section">
                <h3 className="privacy-title">Data Storage</h3>
                <p className="privacy-content">
                    All game data is stored:
                </p>
                <ul className="privacy-list">
                    <li>Locally on your device using browser storage</li>
                    <li>Temporarily on our servers to calculate game statistics</li>
                </ul>
                <p className="privacy-content">
                    We do not use cookies to track you across different websites.
                </p>
            </div>
            
            <div className="privacy-section">
                <h3 className="privacy-title">Information Sharing</h3>
                <p className="privacy-content">
                    We do not sell, trade, or otherwise transfer your information to third parties. 
                    Anonymous, aggregated statistics may be shared (such as "X% of players completed all levels today"), 
                    but this information cannot be used to identify individual users.
                </p>
            </div>
            
            <div className="privacy-section">
                <h3 className="privacy-title">Your Rights</h3>
                <p className="privacy-content">
                    Since we only collect anonymous data, there is limited personal data to access or control. However, you can:
                </p>
                <ul className="privacy-list">
                    <li>Clear your local storage to reset your game data</li>
                    <li>Contact us if you have questions about our data practices</li>
                </ul>
            </div>
            
            <div className="privacy-section">
                <h3 className="privacy-title">Children's Privacy</h3>
                <p className="privacy-content">
                    WordCrown is suitable for all ages. We do not knowingly collect data from children under 13, 
                    and our random ID system ensures we don't gather personally identifiable information from any user.
                </p>
            </div>
            
            <div className="privacy-section">
                <h3 className="privacy-title">Changes to This Privacy Policy</h3>
                <p className="privacy-content">
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the 
                    new Privacy Policy on this page and updating the "Last Updated" date.
                </p>
            </div>
            
            <div className="privacy-section">
                <h3 className="privacy-title">Contact Us</h3>
                <p className="privacy-content">
                    If you have questions about this Privacy Policy, please contact us at:
                </p>
                <ul className="privacy-list">
                    <li>Email: contact@wordcrown.com</li>
                    <li>Mail: WordCrown, 123 Word Lane, Game City, GC 12345</li>
                </ul>
                <p className="privacy-content">Thank you for playing WordCrown!</p>
            </div>
        </div>
    );

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            title="Privacy Policy"
            size="lg"
        >
            {modalContent}
        </BaseModal>
    );
};

export default PrivacyPolicyModal;
