// src/services/versionService.js
import { APP_VERSION } from '../config/version.js';

// Use the global version if defined (in production), otherwise use the imported version
const CURRENT_VERSION = typeof __APP_VERSION__ !== 'undefined' ? __APP_VERSION__ : APP_VERSION;

const VERSION_KEY = 'wordcrown_app_version';
const LAST_CHECK_KEY = 'wordcrown_last_check';
const CHECK_INTERVAL = 60 * 60 * 1000; // Check every hour (in milliseconds)

class VersionService {
    constructor() {
        this.storedVersion = localStorage.getItem(VERSION_KEY);
        // console.log('VersionService initialized:', { 
        //     stored: this.storedVersion, 
        //     current: CURRENT_VERSION
        // });
    }

    initialize() {
        // Set initial version if not set
        if (!this.storedVersion) {
            // console.log('No stored version found, setting initial version:', CURRENT_VERSION);
            localStorage.setItem(VERSION_KEY, CURRENT_VERSION);
            this.storedVersion = CURRENT_VERSION;
        }
        
        // Check if app should update
        this.checkForUpdates();
        
        // Set up periodic checks
        this.setupUpdateCheck();
    }
    
    checkForUpdates() {
        // console.log('Checking for updates...', { 
        //     stored: this.storedVersion, 
        //     current: CURRENT_VERSION 
        // });
        
        // If stored version doesn't match current version, refresh the page
        if (this.storedVersion !== CURRENT_VERSION) {
            // console.log(`App update detected: ${this.storedVersion} → ${CURRENT_VERSION}`);
            localStorage.setItem(VERSION_KEY, CURRENT_VERSION);
            
            // Refresh the page immediately in production
            if (!import.meta.env.DEV) {
                window.location.reload();
            } else {
                console.log('DEV MODE: Would refresh in production, but skipping in dev mode');
            }
            return true;
        }
        return false;
    }
    
    setupUpdateCheck() {
        // Check if we should perform a check based on time interval
        const lastCheck = localStorage.getItem(LAST_CHECK_KEY);
        const now = Date.now();
        
        if (!lastCheck || (now - parseInt(lastCheck)) > CHECK_INTERVAL) {
            // Update last check timestamp
            localStorage.setItem(LAST_CHECK_KEY, now.toString());
            
            // Perform a periodic version check
            this.checkForUpdates();
        }
        
        // Set up interval for future checks
        const intervalId = setInterval(() => {
            localStorage.setItem(LAST_CHECK_KEY, Date.now().toString());
            this.checkForUpdates();
        }, CHECK_INTERVAL);
        
        // Also check when the page becomes visible again
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                this.checkForUpdates();
            }
        });
    }
    
    // Dev-only method to manually simulate a version change
    simulateVersionChange(newVersion) {
        if (!import.meta.env.DEV) return;
        
        console.log(`Simulating version change: ${CURRENT_VERSION} → ${newVersion}`);
        this.storedVersion = CURRENT_VERSION;
        
        // Create a temporary object to mock the new version
        window.__APP_VERSION__ = newVersion;
        
        // Replace the normal check function with one that uses our mock version
        const originalCheck = this.checkForUpdates;
        this.checkForUpdates = () => {
            console.log('Checking with simulated version...', { 
                stored: this.storedVersion, 
                current: window.__APP_VERSION__ 
            });
            
            if (this.storedVersion !== window.__APP_VERSION__) {
                console.log(`Simulated update detected: ${this.storedVersion} → ${window.__APP_VERSION__}`);
                localStorage.setItem(VERSION_KEY, window.__APP_VERSION__);
                
                console.log('DEV MODE: Would refresh in production, but skipping in dev mode');
                return true;
            }
            return false;
        };
        
        // Perform the check
        const result = this.checkForUpdates();
        
        // Restore the original function after a delay
        setTimeout(() => {
            this.checkForUpdates = originalCheck;
            delete window.__APP_VERSION__;
            console.log('Restored original version checking function');
        }, 5000);
        
        return result;
    }
}

export const versionService = new VersionService();
