// src/services/timeService.js

/**
 * Service for handling time and date operations, particularly focused on
 * Eastern Time zone calculations for the game's day cycle.
 */

/**
 * Get the current date in Eastern Time zone
 * @param {Date} date - Optional date to convert (defaults to current date)
 * @param {Object} options - Locale options
 * @returns {String} - Formatted date string in Eastern Time
 */
export const getEasternTimeDate = (date = new Date(), options = {}) => {
    // Default options
    const defaultOptions = {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short'
    };

    // Merge default options with provided options
    const mergedOptions = { ...defaultOptions, ...options };

    // Return the date string in Eastern Time
    return date.toLocaleString('en-US', mergedOptions);
};

/**
 * Get date string in Eastern Time formatted as MM/DD/YYYY
 * Used primarily for puzzle lookup
 * @param {Date} date - Optional date to convert (defaults to current date)
 * @returns {String} - Date string in MM/DD/YYYY format in Eastern Time
 */
export const getEasternDateForPuzzle = (date = new Date()) => {
    const options = {
        timeZone: 'America/New_York',
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
    };

    return date.toLocaleString('en-US', options);
};

/**
 * Check if two dates are the same day in Eastern Time
 * @param {Date|String} date1 - First date to compare
 * @param {Date|String} date2 - Second date to compare
 * @returns {Boolean} - True if dates represent the same day in Eastern Time
 */
export const isSameEasternDay = (date1, date2) => {
    // Convert strings to Date objects if needed
    const d1 = typeof date1 === 'string' ? new Date(date1) : date1;
    const d2 = typeof date2 === 'string' ? new Date(date2) : date2;

    // Get Eastern Time formatted dates
    const et1 = getEasternTimeDate(d1, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    });

    const et2 = getEasternTimeDate(d2, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    });

    return et1 === et2;
};

/**
 * Calculate yesterday's date in Eastern Time
 * @returns {String} - Yesterday's date string in Eastern Time
 */
export const getEasternYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return getEasternTimeDate(yesterday);
};

/**
 * Calculate the number of days between two dates in Eastern Time
 * @param {Date|String} startDate - Starting date
 * @param {Date|String} endDate - Ending date (defaults to current date)
 * @returns {Number} - Number of days between the dates
 */
export const daysBetweenInEasternTime = (startDate, endDate = new Date()) => {
    // Convert to Date objects if strings
    const start = typeof startDate === 'string' ? new Date(startDate) : startDate;
    const end = typeof endDate === 'string' ? new Date(endDate) : endDate;

    // Get year, month, day components in Eastern Time for start date
    const startOptions = {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    };

    const startParts = new Intl.DateTimeFormat('en-US', startOptions)
        .formatToParts(start)
        .reduce((acc, part) => {
            if (part.type !== 'literal') acc[part.type] = parseInt(part.value);
            return acc;
        }, {});

    // Get year, month, day components in Eastern Time for end date
    const endParts = new Intl.DateTimeFormat('en-US', startOptions)
        .formatToParts(end)
        .reduce((acc, part) => {
            if (part.type !== 'literal') acc[part.type] = parseInt(part.value);
            return acc;
        }, {});

    // Create Date objects for comparison (months are 0-indexed)
    const startET = new Date(startParts.year, startParts.month - 1, startParts.day);
    const endET = new Date(endParts.year, endParts.month - 1, endParts.day);

    // Calculate difference in days
    const diffTime = Math.abs(endET - startET);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
};

/**
 * Check if a date is today in Eastern Time
 * @param {Date|String} date - Date to check
 * @returns {Boolean} - True if the date is today in Eastern Time
 */
export const isEasternToday = (date) => {
    return isSameEasternDay(date, new Date());
};

/**
 * Format a date for display in the game
 * @param {Date|String} date - Date to format
 * @returns {String} - Formatted date string
 */
export const formatGameDate = (date) => {
    const options = {
        timeZone: 'America/New_York',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    };

    return new Date(date).toLocaleString('en-US', options);
};

// Export the service as an object for organization
const timeService = {
    getEasternTimeDate,
    getEasternDateForPuzzle,
    isSameEasternDay,
    getEasternYesterday,
    daysBetweenInEasternTime,
    isEasternToday,
    formatGameDate
};

export default timeService;
