// src/config/gameConfig.js
import { PUZZLES, getPuzzleForDate } from './puzzles';

/**
 * Main game configuration object.
 * Centralizes all game settings and parameters.
 */
export const GAME_CONFIG = {
    // Game mechanics
    maxInputLength: 15,
    minInputLength: 3,
    enableEasyMode: true,
    maxMissedAttempts: 5, // Maximum number of missed attempts allowed

    // Game mode configuration
    mode: {
        name: "Word Ladder",
        emoji: "👑",
        lossEmoji: "🙉",
        rewardName: "crown",
        themeClass: "theme-standard"
    },

    // API settings
    api: {
        baseUrl: import.meta.env.VITE_API_URL || 'http://localhost:3002',
        timeout: 10000,
        useForStats: import.meta.env.VITE_USE_API !== 'false'
    },

    // Level-specific messages
    levelMessages: {
        1: {
            title: "Great Start!",
            subtitle: "Your journey begins!",
        },
        2: {
            title: "Level Up!",
            subtitle: "You're making progress!",
        },
        3: {
            title: "Almost Halfway!",
            subtitle: "Keep the momentum going!",
        },
        4: {
            title: "Halfway There!",
            subtitle: "The rest of the castle awaits!",
        },
        5: {
            title: "Impressive!",
            subtitle: "The next challenge awaits!",
        },
        6: {
            title: "Amazing!",
            subtitle: "Getting closer to the crown!",
        },
        7: {
            title: "Wordsmith!",
            subtitle: "Just one more level to victory!",
        },
        8: {
            title: "Crowned Champion!",
            subtitle: "You've claimed the crown!",
        }
    },

    // Reference to puzzles collection
    puzzles: PUZZLES,

    // Use the imported getPuzzleForDate function
    getPuzzleForDate,
};

// Create a consolidated config index file
export default GAME_CONFIG;
