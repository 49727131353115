// src/services/apiService.js
import axios from 'axios';

// Import config (assuming you have a consolidated config)
// If not, you can use environment variables directly
const API_BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3002';
const API_TIMEOUT = 10000;
const USE_API_FOR_STATS = import.meta.env.VITE_USE_API !== 'false';

// Create API client
const apiClient = axios.create({
    baseURL: API_BASE_URL,
    timeout: API_TIMEOUT,
    headers: {
        'Content-Type': 'application/json',
    }
});

/**
 * API Service for handling all external API communications.
 * This centralizes all API calls and provides consistent error handling.
 */
const apiService = {
    // Service configuration
    isEnabled: USE_API_FOR_STATS,

    /**
     * Enable or disable the API service
     * @param {boolean} enabled - Whether the API should be enabled
     * @returns {boolean} - The new enabled state
     */
    setEnabled(enabled) {
        this.isEnabled = enabled;
        return this.isEnabled;
    },

    /**
     * Test the API connection
     * @returns {Promise<Object>} - Connection status information
     */
    async testConnection() {
        try {
            const response = await apiClient.get('/api/health');
            return {
                success: true,
                data: response.data,
                message: 'Successfully connected to the API'
            };
        } catch (error) {
            console.error('API Connection failed:', error);
            return {
                success: false,
                error: error.message,
                message: `Failed to connect to the API: ${error.message}`
            };
        }
    },

    /**
     * Record a game completion
     * @param {string} userId - The user's ID
     * @param {number} totalGuesses - Total number of guesses made
     * @param {number} levelReached - Highest level reached (1-indexed)
     * @param {boolean} completed - Whether the game was completed successfully
     * @returns {Promise<Object>} - Updated stats and streak information
     */
    async recordCompletion(userId, totalGuesses, levelReached, completed) {
        if (!this.isEnabled) {
            throw new Error('API is disabled');
        }

        try {
            const response = await apiClient.post('/api/record-completion', {
                userId,
                totalGuesses,
                levelReached,
                completed
            });

            return response.data;
        } catch (error) {
            console.error('Record completion failed:', error);
            throw error;
        }
    },

    /**
     * Get daily game statistics
     * @returns {Promise<Object>} - Daily statistics
     */
    async getDailyStats() {
        if (!this.isEnabled) {
            return {
                totalWinners: 0,
                averageGuesses: 0,
                averageLevel: 0,
                totalCompletions: 0
            };
        }

        try {
            const response = await apiClient.get('/api/get-stats');
            return response.data;
        } catch (error) {
            console.error('Failed to fetch daily stats:', error);
            throw error;
        }
    },

    /**
     * Get statistics for a specific user
     * @param {string} userId - The user's ID
     * @returns {Promise<Object>} - User statistics including streaks
     */
    async getUserStats(userId) {
        if (!this.isEnabled) {
            return {
                currentStreak: 0,
                longestStreak: 0,
                lastPlayed: null
            };
        }

        try {
            const response = await apiClient.get(`/api/user-stats/${userId}`);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch user stats:', error);
            throw error;
        }
    },

    /**
     * Admin function to get detailed game statistics
     * Only works if called from an authorized IP
     * @returns {Promise<Object>} - Detailed admin statistics
     */
    async getAdminStats() {
        if (!this.isEnabled) {
            throw new Error('API is disabled');
        }

        try {
            const response = await apiClient.get('/api/admin/stats');
            return response.data;
        } catch (error) {
            console.error('Failed to fetch admin stats:', error);
            throw error;
        }
    },

    /**
     * Get the raw axios client for custom API calls
     * @returns {AxiosInstance} - The axios client instance
     */
    getClient() {
        return apiClient;
    }
};

export default apiService;
