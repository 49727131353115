// src/components/game/LevelProgress.jsx
import React from 'react';
import { LockClosedIcon, CheckIcon } from '@heroicons/react/24/outline';

/**
 * Level Progress Component
 * Displays the progress through game levels with completed, current, and locked states
 */
function LevelProgress({ levels, currentLevel }) {
    return (
        <div className="level-progress">
            {levels.map((level, index) => (
                <div
                    key={index}
                    className={`level-item ${
                        index === currentLevel ? 'level-item--current' : ''
                    } ${level.isComplete ? 'level-item--completed' : ''}`}
                >
                    <div className="level-content">
                        <span className="level-number">Level {index + 1}</span>
                        {level.isLocked ? (
                            <LockClosedIcon className="level-icon level-icon--locked" />
                        ) : level.isComplete ? (
                            <CheckIcon className="level-icon level-icon--complete" />
                        ) : null}
                    </div>
                    {(level.isComplete || !level.isLocked) && level.word && (
                        <div className="level-word">
                            {level.isComplete ? (
                                <span className="level-word--complete">{level.word}</span>
                            ) : null}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default LevelProgress;
