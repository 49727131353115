// src/utils/wordUtils.js
export const getLetterMatches = (guess, target) => {
    const guessArray = guess.split('');
    const targetArray = target.split('');
    
    // First pass: Mark exact matches and count remaining target letters
    const result = guessArray.map((letter, index) => ({
        letter,
        isExactMatch: letter === targetArray[index],
        isInWord: false,
        index
    }));

    // Count remaining letters in target (excluding exact matches)
    const targetLetterCount = {};
    targetArray.forEach((letter, index) => {
        if (guessArray[index] !== letter) {
            targetLetterCount[letter] = (targetLetterCount[letter] || 0) + 1;
        }
    });

    // Second pass: Mark letters that exist in word but aren't exact matches
    result
        .filter(item => !item.isExactMatch && targetLetterCount[item.letter] > 0)
        .forEach(item => {
            if (targetLetterCount[item.letter] > 0) {
                result[item.index].isInWord = true;
                targetLetterCount[item.letter]--;
            }
        });

    return result;
};

// Get exact matches (green letters) from a guess
export const getExactMatches = (guess, target) => {
    const matches = new Map(); // position -> letter
    const guessArray = guess.split('');
    const targetArray = target.split('');

    guessArray.forEach((letter, index) => {
        if (letter === targetArray[index]) {
            matches.set(index, letter);
        }
    });

    return matches;
};

// Get positions with letters in the word but wrong position (yellow letters)
export const getInWordMatches = (guess, target) => {
    const matches = new Map(); // position -> letter
    const letterMatches = getLetterMatches(guess, target);
    
    letterMatches.forEach(match => {
        if (match.isInWord && !match.isExactMatch) {
            matches.set(match.index, match.letter);
        }
    });
    
    return matches;
};

// Convert a state array (with locked positions) to input string 
// (excluding the locked positions)
export const stateToInputWord = (state, greenPositions) => {
    return state
        .filter((letter, index) => letter !== null && !greenPositions.has(index))
        .join('');
};

// Combine current input with green letters to form the full word
export const buildFullWord = (inputWord, greenLetters, wordLength) => {
    // Initialize array with nulls
    const result = Array(wordLength).fill(null);
    
    // Fill in green letters
    greenLetters.forEach((letter, position) => {
        result[position] = letter;
    });
    
    // Fill in input for non-green positions
    const inputArray = inputWord.split('');
    let inputIndex = 0;
    
    for (let i = 0; i < result.length; i++) {
        if (result[i] === null) {
            if (inputIndex < inputArray.length) {
                result[i] = inputArray[inputIndex];
                inputIndex++;
            }
        }
    }
    
    return result.map(letter => letter || '').join('');
};

/**
 * Combine current input with green letters to form the full word
 * @param {string} userInput - The user's current input
 * @param {Map} greenLetters - Map of positions to letters that are already correct (green)
 * @param {number} wordLength - The total length of the target word
 * @returns {string} - The full word guess combining green letters and user input
 */
export const buildFullGuess = (userInput, greenLetters, wordLength) => {
    // Initialize array with placeholders
    const result = Array(wordLength).fill(null);
    
    // First, fill in green letters
    greenLetters.forEach((letter, position) => {
        result[position] = letter;
    });
    
    // Get positions that need user input (non-green positions)
    const editablePositions = [];
    for (let i = 0; i < result.length; i++) {
        if (result[i] === null) {
            editablePositions.push(i);
        }
    }
    
    // Fill in user input
    const userInputArray = userInput.split('');
    for (let i = 0; i < Math.min(userInputArray.length, editablePositions.length); i++) {
        result[editablePositions[i]] = userInputArray[i];
    }
    
    return result.map(letter => letter || '').join('');
};
