// src/components/modals/CompletionModal.jsx
import React, { useState, useEffect, useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ShareIcon, TrophyIcon, ChartBarIcon } from '@heroicons/react/24/solid';
import { GAME_CONFIG } from '../../config/gameConfig';

const CompletionModal = ({ isOpen, onClose, gameState }) => {
    const [copyStatus, setCopyStatus] = useState('');
    const modeConfig = GAME_CONFIG.mode;
    const modalRef = useRef(null);
    const [activeTab, setActiveTab] = useState('stats'); // 'stats' or 'distribution'

    // Handle escape key to close modal
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') onClose();
        };

        if (isOpen) {
            window.addEventListener('keydown', handleEscape);
        }

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [isOpen, onClose]);

    // Prevent scrolling of the background when modal is open
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    if (!isOpen || !gameState) return null;

    // Determine if player completed all levels
    const completedAllLevels = gameState.currentLevel >= gameState.levels.length - 1 &&
        gameState.levels[gameState.levels.length - 1].isComplete;

    // Calculate the level reached (1-indexed)
    const levelReached = gameState.currentLevel + 1;
    const totalLevels = gameState.levels.length;

    // Get the correct word of the level where the player failed
    const failedLevelWord = gameState.levels[gameState.currentLevel].word;

    // Get streak information
    const currentStreak = gameState.streak || 0;
    const longestStreak = gameState.longestStreak || 0;

    // Get the category from the game state
    const category = gameState.category || "Word Ladder";

    // Prepare the share message based on success or not
    const shareMessage = completedAllLevels
        ? `I claimed today's ${modeConfig.emoji} at wordcrown.com in ${gameState.totalGuesses} guesses and have a ${currentStreak} crown streak!`
        : `I reached level ${levelReached}/${totalLevels} at wordcrown.com today! Can you do better? Category: ${category}`;

    const handleShare = async () => {
        // Try to use the native share API first (mobile devices)
        if (navigator.share) {
            try {
                await navigator.share({
                    text: shareMessage
                });
                setCopyStatus('Shared successfully!');
            } catch (err) {
                // Fall back to clipboard if share is cancelled or fails
                handleCopyToClipboard();
            }
        } else {
            // If native sharing isn't available, use clipboard
            handleCopyToClipboard();
        }
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(shareMessage);
        setCopyStatus('Score copied, paste it in a message to your friends!');
        setTimeout(() => setCopyStatus(''), 3000);
    };

    // Handle overlay click to close
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    // Find the max guesses for any level to scale the bars
    const maxGuesses = Math.max(
        ...gameState.levels
            .filter(level => level.guesses.length > 0)
            .map(level => level.guesses.length),
        1
    );

    return (
        <div className="completion-modal-overlay" onClick={handleOverlayClick}>
            <div className="completion-modal" ref={modalRef}>
                <button
                    onClick={onClose}
                    className="modal-close-button"
                    aria-label="Close modal"
                >
                    <XMarkIcon className="modal-close-icon" />
                </button>

                {/* Title */}
                <h2 className={`modal-title ${completedAllLevels ? "text-green-400" : "text-red-400"}`}>
                    {completedAllLevels
                        ? (
                            <>
                                <span className="emoji-crown">{modeConfig.emoji}</span>
                                <span className="title-text">Congratulations!</span>
                            </>
                        )
                        : (
                            <>
                                <span className="emoji-crown">{modeConfig.lossEmoji}</span>
                                <span className="title-text">Game Over</span>
                            </>
                        )
                    }
                </h2>

                {/* Success Message */}
                <div className="completion-message">
                    {completedAllLevels ? (
                        <div>
                            <p>You've climbed today's castle and earned your crown!</p>
                            {currentStreak > 1 && <p className="streak-highlight">🔥 {currentStreak} day streak! 🔥</p>}
                        </div>
                    ) : (
                        <div>
                            <p>You made it to level {levelReached} out of {totalLevels}.</p>
                            <p>Try again tomorrow for a new set of words!</p>
                        </div>
                    )}
                </div>

                {/* Word display */}
                <div className="word-display">
                    <span className={`word ${completedAllLevels ? "text-green-400" : "text-red-400"} font-bold font-mono`}>
                        {failedLevelWord}
                    </span>
                </div>
                
                {/* Category Badge */}
                <div className="category-badge">
                    <span className='text-white'>Category: </span>
                    <span className='font-bold'>{category}</span>
                </div>

                {/* Tab Navigation */}
                <div className="modal-tabs">
                    <button
                        className={`tab-button ${activeTab === 'stats' ? 'tab-button-active' : ''}`}
                        onClick={() => setActiveTab('stats')}
                    >
                        <TrophyIcon className="tab-icon" />
                        <span>Stats</span>
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'distribution' ? 'tab-button-active' : ''}`}
                        onClick={() => setActiveTab('distribution')}
                    >
                        <ChartBarIcon className="tab-icon" />
                        <span>Levels</span>
                    </button>
                </div>

                {/* Stats Tab Content */}
                {activeTab === 'stats' && (
                    <div className="tab-content">
                        {/* Player Stats */}
                        <div className="stats-container">
                            <div className="stat-item">
                                <div className="stat-value">{gameState.totalGuesses}</div>
                                <div className="stat-label">Guesses</div>
                            </div>
                            <div className="stat-item">
                                <div className="stat-value">{currentStreak}</div>
                                <div className="stat-label">Streak</div>
                            </div>
                            <div className="stat-item">
                                <div className="stat-value">{longestStreak}</div>
                                <div className="stat-label">Best</div>
                            </div>
                        </div>

                        {/* Global Stats */}
                        {gameState.dailyStats && (
                            <div className="daily-stats">
                                <div className="stat-item">
                                    <div className="stat-value">{gameState.dailyStats.totalWinners || 0}</div>
                                    <div className="stat-label">Players</div>
                                </div>
                                <div className="stat-item">
                                    <div className="stat-value">{gameState.dailyStats.averageGuesses || 0}</div>
                                    <div className="stat-label">Avg Guesses</div>
                                </div>
                                <div className="stat-item">
                                    <div className="stat-value">{gameState.dailyStats.averageLevel || 0}</div>
                                    <div className="stat-label">Avg Level</div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/* Distribution Tab Content */}
                {activeTab === 'distribution' && (
                    <div className="tab-content">
                        <div className="guess-distribution">
                            <div className="bar-chart">
                                {gameState.levels.map((level, index) => {
                                    // Only show levels that were played
                                    if (level.guesses.length === 0 && index > gameState.currentLevel) {
                                        return null;
                                    }

                                    // Determine if this is the current level
                                    const isCurrent = index === gameState.currentLevel;
                                    // Determine if this level is complete
                                    const isComplete = level.isComplete;

                                    return (
                                        <div key={index} className="bar-row">
                                            <div className="bar-label">Level {index + 1}</div>
                                            <div className="bar-container">
                                                <div
                                                    className={`bar-fill ${isCurrent && !isComplete ? 'bar-fill-current' : ''} ${isComplete ? 'bar-fill-complete' : ''}`}
                                                    style={{
                                                        width: `${(level.guesses.length / maxGuesses) * 100}%`
                                                    }}
                                                >
                                                    <span className="bar-value">{level.guesses.length || 0}</span>
                                                </div>
                                            </div>
                                            {isComplete && (
                                                <div className="bar-word">{level.word}</div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}

                {/* Share Button */}
                <button onClick={handleShare} className="share-button">
                    <ShareIcon className="h-4 w-4 me-2" />
                    <span>Share with Friends</span>
                </button>

                {copyStatus && (
                    <div className="copy-status">{copyStatus}</div>
                )}
            </div>
        </div>
    );
};

export default CompletionModal;
