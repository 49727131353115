// src/hooks/useKeyboardInput.js
import { useEffect } from 'react';

/**
 * Custom hook to handle both physical and virtual keyboard inputs
 * @param {Object} options - Options for keyboard input handling
 * @param {Function} options.onKeyPress - Function to handle key press
 * @param {Function} options.onBackspace - Function to handle backspace
 * @param {Function} options.onSubmit - Function to handle submit/enter
 * @param {boolean} options.isDisabled - Whether keyboard input is disabled
 * @returns {void}
 */
export function useKeyboardInput({
    onKeyPress,
    onBackspace,
    onSubmit,
    isDisabled = false
}) {
    useEffect(() => {
        // If disabled, don't add event listeners
        if (isDisabled) return;

        const handleKeyDown = (event) => {
            // Ignore keyboard events if input is disabled
            if (isDisabled) return;

            // Handle letter keys (a-z, A-Z)
            if (/^[a-zA-Z]$/.test(event.key)) {
                event.preventDefault();
                onKeyPress(event.key.toUpperCase());
            }
            // Handle backspace key
            else if (event.key === 'Backspace') {
                event.preventDefault();
                onBackspace();
            }
            // Handle enter key
            else if (event.key === 'Enter') {
                event.preventDefault();
                onSubmit();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Clean up event listener on unmount or when dependencies change
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onKeyPress, onBackspace, onSubmit, isDisabled]);

    // This hook doesn't return anything, it just sets up the event listeners
    return null;
}

export default useKeyboardInput;
