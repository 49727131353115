// src/components/layout/AppFooter.jsx
import React from 'react';

/**
 * AppFooter component
 * Contains support information and manual refresh option
 */
function AppFooter({ onLinkClick }) {
    const handleManualRefresh = () => {
        // Clear relevant storage and reload
        localStorage.removeItem('wordcrown_game_state');
        localStorage.removeItem('wordcrown_current_date');
        localStorage.removeItem('wordcrown_puzzle_date');
        window.location.reload();
    };

    const currentYear = new Date().getFullYear();

    return (
        <footer className="app-footer">
            <div className="app-footer-content">
                {/* Manual refresh button for users experiencing issues */}
                {/* <div className="text-center">
                    <button 
                        onClick={handleManualRefresh}
                        className="text-xs text-gray-400 hover:text-gray-300 underline"
                    >
                        Not seeing today's puzzle? Click here
                    </button>
                </div> */}
                <div className="copyright">
                    © {currentYear} WordCrown. All rights reserved.
                </div>
                <button 
                        onClick={() => onLinkClick('privacy')}
                        className="app-footer-privacy"
                    >
                        Privacy Policy
                </button>
            </div>
        </footer>
    );
}

export default AppFooter;
