// src/services/statsService.js
const STATS_KEY = 'wordcrown_daily_stats';

class StatsService {
    getDailyStats() {
        const today = new Date().toDateString();
        const savedStats = localStorage.getItem(STATS_KEY);
        
        if (savedStats) {
            const stats = JSON.parse(savedStats);
            if (stats.date === today) {
                return stats;
            }
        }

        // If no stats or new day, initialize fresh stats
        const newStats = {
            date: today,
            winners: [],
            totalGuesses: 0,
            totalLevelReached: 0,
            completions: 0,
            perfectCompletions: 0
        };

        this.saveDailyStats(newStats);
        return newStats;
    }

    recordCompletion(userId, totalGuesses, levelReached, perfectCompletion = false) {
        const stats = this.getDailyStats();
        
        // Only count if this user hasn't already played today
        if (!stats.winners.includes(userId)) {
            const newStats = {
                ...stats,
                winners: [...stats.winners, userId],
                totalGuesses: stats.totalGuesses + totalGuesses,
                totalLevelReached: stats.totalLevelReached + levelReached,
                completions: stats.completions + 1,
                perfectCompletions: stats.perfectCompletions + (perfectCompletion ? 1 : 0)
            };
            
            this.saveDailyStats(newStats);
            return newStats;
        }
        
        return stats;
    }

    saveDailyStats(stats) {
        localStorage.setItem(STATS_KEY, JSON.stringify(stats));
    }

    getAverageGuesses() {
        const stats = this.getDailyStats();
        if (stats.completions === 0) return 0;
        return Math.round(stats.totalGuesses / stats.completions);
    }

    getTotalWinners() {
        const stats = this.getDailyStats();
        return stats.winners.length;
    }
    
    getAverageLevel() {
        const stats = this.getDailyStats();
        if (stats.completions === 0) return 0;
        return Math.round(stats.totalLevelReached / stats.completions);
    }
    
    getPerfectCompletions() {
        const stats = this.getDailyStats();
        return stats.perfectCompletions;
    }
}

export const statsService = new StatsService();
