// src/components/game/GameControls.jsx
import React from 'react';
import GAME_CONFIG from '../../config';

/**
 * GameControls component
 * Displays game status indicators and controls
 */
function GameControls({ currentLevel, chainRef }) {
    return (
        <div className="game-header">
            <div className="header-stats">
                <div className="missed-attempts-display" ref={chainRef}>
                    <div className="missed-attempts-label">Misses:</div>
                    <div className="missed-attempts-counter">
                        {Array(GAME_CONFIG.maxMissedAttempts).fill(0).map((_, i) => (
                            <span
                                key={i}
                                className={`missed-attempt-dot ${
                                    i < currentLevel.missedAttempts 
                                        ? 'missed-attempt-dot--used' 
                                        : ''
                                }`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GameControls;
