// src/services/storageService.js

/**
 * Service for handling all localStorage operations.
 * This centralizes storage access and provides consistent error handling.
 */

// Storage keys
const STORAGE_KEYS = {
    GAME_STATE: 'wordcrown_game_state',
    STREAK: 'wordcrown_streak',
    USER_ID: 'wordcrown_user_id',
    HAS_PLAYED: 'wordcrown_has_played',
    DAILY_STATS: 'wordcrown_daily_stats',
    CURRENT_DATE: 'wordcrown_current_date',
    PUZZLE_DATE: 'wordcrown_puzzle_date',
};

/**
 * Centralized storage service for managing localStorage
 */
export const storageService = {
    /**
     * Get an item from localStorage
     * @param {string} key - The storage key
     * @returns {string|null} - The stored value or null if not found
     */
    getItem(key) {
        try {
            return localStorage.getItem(key);
        } catch (error) {
            console.error(`Error getting ${key} from localStorage:`, error);
            return null;
        }
    },

    /**
     * Set an item in localStorage
     * @param {string} key - The storage key
     * @param {string} value - The value to store
     * @returns {boolean} - Whether the operation was successful
     */
    setItem(key, value) {
        try {
            localStorage.setItem(key, value);
            return true;
        } catch (error) {
            console.error(`Error setting ${key} in localStorage:`, error);
            return false;
        }
    },

    /**
     * Remove an item from localStorage
     * @param {string} key - The storage key to remove
     * @returns {boolean} - Whether the operation was successful
     */
    removeItem(key) {
        try {
            localStorage.removeItem(key);
            return true;
        } catch (error) {
            console.error(`Error removing ${key} from localStorage:`, error);
            return false;
        }
    },

    /**
     * Get the game state from localStorage
     * @param {string} [key=STORAGE_KEYS.GAME_STATE] - Optional custom key
     * @returns {Object|null} - The parsed game state or null if not found
     */
    getGameState(key = STORAGE_KEYS.GAME_STATE) {
        const state = this.getItem(key);
        return state ? JSON.parse(state) : null;
    },

    /**
     * Save the game state to localStorage
     * @param {string} key - The storage key
     * @param {Object} state - The game state to save
     * @returns {boolean} - Whether the operation was successful
     */
    saveGameState(key, state) {
        return this.setItem(key, JSON.stringify(state));
    },

    /**
     * Get the user ID from localStorage or generate a new one
     * @returns {string} - The user ID
     */
    getUserId() {
        let userId = this.getItem(STORAGE_KEYS.USER_ID);
        if (!userId) {
            userId = 'user_' + Math.random().toString(36).substr(2, 9);
            this.setItem(STORAGE_KEYS.USER_ID, userId);
        }
        return userId;
    },

    /**
     * Get the streak data from localStorage
     * @returns {Object} - The streak data
     */
    getStreakData() {
        const data = this.getItem(STORAGE_KEYS.STREAK);
        return data ? JSON.parse(data) : { streak: 0, lastComplete: null };
    },

    /**
     * Save streak data to localStorage
     * @param {Object} data - The streak data to save
     * @returns {boolean} - Whether the operation was successful
     */
    saveStreakData(data) {
        return this.setItem(STORAGE_KEYS.STREAK, JSON.stringify(data));
    },

    /**
     * Check if the user has played before
     * @returns {boolean} - Whether the user has played before
     */
    hasPlayedBefore() {
        return this.getItem(STORAGE_KEYS.HAS_PLAYED) === 'true';
    },

    /**
     * Mark the user as having played
     * @returns {boolean} - Whether the operation was successful
     */
    markAsPlayed() {
        return this.setItem(STORAGE_KEYS.HAS_PLAYED, 'true');
    },

    /**
     * Get the current date from localStorage
     * @returns {string|null} - The stored date or null if not found
     */
    getCurrentDate() {
        return this.getItem(STORAGE_KEYS.CURRENT_DATE);
    },

    /**
     * Save the current date to localStorage
     * @param {string} date - The date to save
     * @returns {boolean} - Whether the operation was successful
     */
    setCurrentDate(date) {
        return this.setItem(STORAGE_KEYS.CURRENT_DATE, date);
    },

    /**
     * Get the puzzle date from localStorage
     * @returns {string|null} - The stored puzzle date or null if not found
     */
    getPuzzleDate() {
        return this.getItem(STORAGE_KEYS.PUZZLE_DATE);
    },

    /**
     * Save the puzzle date to localStorage
     * @param {string} date - The puzzle date to save
     * @returns {boolean} - Whether the operation was successful
     */
    setPuzzleDate(date) {
        return this.setItem(STORAGE_KEYS.PUZZLE_DATE, date);
    },

    /**
     * Clear all game data from localStorage
     * @returns {boolean} - Whether the operation was successful
     */
    clearGameData() {
        try {
            Object.values(STORAGE_KEYS).forEach(key => {
                this.removeItem(key);
            });
            return true;
        } catch (error) {
            console.error('Error clearing game data:', error);
            return false;
        }
    }
};

export default storageService;
