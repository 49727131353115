// src/components/modals/HowToPlayModal.jsx
import React from 'react';
import BaseModal from './BaseModal';
import { GAME_CONFIG } from '../../config/gameConfig';

/**
 * HowToPlayModal - Displays game instructions using the BaseModal component
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to call when modal is closed
 * @param {string} props.currentMode - Current game mode ('normal' or 'expert')
 * @returns {React.ReactElement|null}
 */
const HowToPlayModal = ({ isOpen, onClose, currentMode = 'normal' }) => {
    // Get current mode configuration
    const modeConfig = GAME_CONFIG.modes ? GAME_CONFIG.modes[currentMode] : GAME_CONFIG.mode;
    const rewardName = modeConfig.rewardName;
    const emoji = modeConfig.emoji;

    // Modal content
    const modalContent = (
        <div className="how-to-play">
            <div className="how-to-play-section">
                <h3 className="section-title">Goal</h3>
                <p className="how-to-play-item">• Guess all 8 words to reach the castle's peak and claim your {emoji}</p>
            </div>

            <div className="how-to-play-section">
                <h3 className="section-title">Gameplay</h3>
                <p className="how-to-play-item">• You have 5 attempts per word</p>
                <p className="how-to-play-item">• <span className="text-green-400">Green letters</span> are correct</p>
                {GAME_CONFIG.enableEasyMode && (
                    <p className="how-to-play-item">• <span className="text-yellow-400">Orange letters</span> are in the word but wrong position</p>
                )}
                <p className="how-to-play-item">• All words follow the same theme or category</p>
                <p className="how-to-play-item">• The higher you climb, the harder it gets!</p>
            </div>
        </div>
    );

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            title="How to Play"
            className={currentMode === 'expert' ? 'modal--expert' : ''}
            size="md"
        >
            {modalContent}
        </BaseModal>
    );
};

export default HowToPlayModal;
