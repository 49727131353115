// src/components/feedback/MessageDisplay.jsx
import React from 'react';

/**
 * MessageDisplay component
 * Displays game messages with appropriate styling based on message type
 */
function MessageDisplay({ message, messageType }) {
    if (!message) return null;
    
    const messageClass = 
        messageType === 'success' ? 'message--success' : 
        messageType === 'error' ? 'message--invalid' : 
        'message--normal';
    
    return (
        <div className={`message ${messageClass}`}>
            <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
    );
}

export default MessageDisplay;
