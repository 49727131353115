// src/components/feedback/LevelCompleteOverlay.jsx
import React, { useEffect, useRef, useState } from 'react';
import { GAME_CONFIG } from '../../config/gameConfig';

const Confetti = ({ active }) => {
    const canvasRef = useRef(null);
    
    useEffect(() => {
        if (!active || !canvasRef.current) return;
        
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const particles = [];
        
        // Set canvas size
        const setCanvasSize = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };
        setCanvasSize();
        
        // Create particles with celebratory colors
        const colors = ['#70E000', '#38B000', '#9EF01A', '#00A7E1', '#007EA7']; // Green/blue theme
        
        const particleCount = 100;
        
        for (let i = 0; i < particleCount; i++) {
            particles.push({
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
                color: colors[Math.floor(Math.random() * colors.length)],
                radius: Math.random() * 4 + 2,
                velocity: {
                    x: (Math.random() - 0.5) * 15,
                    y: (Math.random() - 0.5) * 15 - 10
                },
                gravity: 0.5,
                opacity: 1
            });
        }
        
        let animationFrame;
        const animate = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            
            particles.forEach((particle, index) => {
                // Update position
                particle.x += particle.velocity.x;
                particle.y += particle.velocity.y;
                particle.velocity.y += particle.gravity;
                particle.opacity -= 0.01;
                
                // Draw particle
                ctx.beginPath();
                ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
                ctx.fillStyle = `${particle.color}${Math.floor(particle.opacity * 255).toString(16).padStart(2, '0')}`;
                ctx.fill();
                
                // Remove particles that are off screen or fully transparent
                if (particle.opacity <= 0 || particle.y > window.innerHeight) {
                    particles.splice(index, 1);
                }
            });
            
            if (particles.length > 0) {
                animationFrame = requestAnimationFrame(animate);
            }
        };
        
        animate();
        
        return () => {
            if (animationFrame) {
                cancelAnimationFrame(animationFrame);
            }
        };
    }, [active]);
    
    return (
        <canvas
            ref={canvasRef}
            className="fixed inset-0 pointer-events-none z-50"
            style={{ opacity: active ? 1 : 0 }}
        />
    );
};

const LevelCompleteOverlay = ({ level, onComplete, message }) => {
    const [showConfetti, setShowConfetti] = useState(false);
    
    // Fallback message if none provided
    const defaultMessage = {
        title: "Level Complete!",
        subtitle: `You completed Level ${level}`
    };
    
    // Use provided message or fallback
    const displayMessage = message || GAME_CONFIG.levelMessages[level] || defaultMessage;
    
    useEffect(() => {
        // Start confetti after text appears
        const confettiTimer = setTimeout(() => {
            setShowConfetti(true);
        }, 400);
        
        // Hide overlay after animation
        const completeTimer = setTimeout(() => {
            onComplete();
        }, 2800);
        
        return () => {
            clearTimeout(confettiTimer);
            clearTimeout(completeTimer);
        };
    }, [onComplete]);
    
    return (
        <div className={`level-complete-overlay level-complete-overlay-${level}`}>
            <Confetti active={showConfetti} />
            <div className="level-complete-content">
                <p className="level-complete-subtitle">Level {level} Complete</p>
                <h2 className="level-complete-text">{displayMessage.title}</h2>
                <p className="level-complete-subtext">
                    {displayMessage.subtitle}
                </p>
            </div>
        </div>
    );
};

export default LevelCompleteOverlay;
