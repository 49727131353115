// src/components/layout/AppHeader.jsx
import React from 'react';

/**
 * AppHeader component
 * Contains the app logo and global controls like help button and feedback button
 */
function AppHeader({ onOpenHowToPlay, onOpenFeedback }) {
    return (
        <div className="app-header">
            <div className="app-header-controls">
                <div className="flex items-center justify-between w-full">
                    {/* Left side - Help button */}
                    <button
                        className="help-button-global"
                        onClick={onOpenHowToPlay}
                        aria-label="How to play"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="help-icon"
                        >
                            <circle cx="12" cy="12" r="10"></circle>
                            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                    </button>

                    {/* Right side - Feedback button */}
                    <button
                        className="feedback-button text-sm px-3 py-1 bg-primary-400 hover:bg-primary-200 text-white rounded-lg transition-colors"
                        onClick={onOpenFeedback}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                        </svg>

                        <span>Feedback</span>
                    </button>
                </div>
            </div>

            <div className="wordcrown-logo">
                <img src="/logo.png" alt="Word Crown logo" />
            </div>
        </div>
    );
}

export default AppHeader;
