// src/services/wordService.js
import axios from 'axios';
import naughtyWords from 'naughty-words';

const DATAMUSE_API_URL = 'https://api.datamuse.com/words';
const badWords = new Set(naughtyWords.en);

// const containsProfanity = (word) => {
//     const lowerWord = word.toLowerCase();
//     return Array.from(badWords).some(badWord => 
//         lowerWord.includes(badWord.toLowerCase())
//     );
// };

const containsProfanity = (word) => {
    // Convert to lowercase and trim any whitespace
    const lowerWord = word.toLowerCase().trim();
    
    // Only check for exact matches
    return badWords.has(lowerWord);
};

export const wordService = {
    async isValidWord(word) {
        // Check profanity first
        if (containsProfanity(word)) {
            return false;
        }

        try {
            // Use DataMuse's sp (spelled like) parameter with exact match
            const response = await axios.get(`${DATAMUSE_API_URL}?sp=${word.toLowerCase()}&max=1`);
            
            // If we get a match and it's exactly our word, it's valid
            return response.data.length > 0 && 
                   response.data[0].word.toLowerCase() === word.toLowerCase();
        } catch (error) {
            console.error('Word validation error:', error);
            return false;
        }
    }
};
