// src/services/emailService.js
import emailjs from '@emailjs/browser';

// Initialize EmailJS with your user ID
// This should be called in your app initialization (e.g., in main.jsx or App.jsx)
export const initEmailService = () => {
    emailjs.init("S0kq-1zNJDVbzNLAH"); // Replace with your EmailJS user ID
};

/**
 * Send feedback email using EmailJS
 * 
 * @param {Object} params - Email parameters
 * @param {string} params.feedback - User feedback message
 * @param {string} params.email - User email (optional)
 * @returns {Promise} - Promise that resolves when email is sent
 */
export const sendFeedbackEmail = async ({ feedback, email = 'Not provided' }) => {
    const templateParams = {
        feedback,
        user_email: email,
        app_name: 'WordCrown',
        date: new Date().toLocaleString()
    };
    
    try {
        const response = await emailjs.send(
            'service_533rbyv',  // Replace with your EmailJS service ID
            'template_kao8zos', // Replace with your EmailJS template ID
            templateParams
        );
        
        console.log('Email sent successfully:', response);
        return { success: true, response };
    } catch (error) {
        console.error('Error sending email:', error);
        throw error;
    }
};

export default {
    initEmailService,
    sendFeedbackEmail
};
