// src/App.jsx
import { useState, useEffect } from 'react';
import { GameProvider, useGame } from './contexts/GameContext';
import Game from './components/game/Game';
import AppHeader from './components/layout/AppHeader';
import AppFooter from './components/layout/AppFooter';
import { HowToPlayModal, PrivacyPolicyModal, LeaveFeedbackModal } from './components/modals';
import LevelCompleteOverlay from './components/feedback/LevelCompleteOverlay';
import ApiTestPanel from './components/admin/ApiTestPanel';
import { gameService } from './services/gameService';
import { versionService } from './services/versionService';
import { initEmailService } from './services/emailService';
import GAME_CONFIG from './config';

// Initialize email service
initEmailService();

// Main app content that uses the game context
function AppContent() {
    const { gameState, isLoading, error, checkForNewDay } = useGame();
    const [showHowToPlay, setShowHowToPlay] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [showLevelComplete, setShowLevelComplete] = useState(false);
    const [completedLevel, setCompletedLevel] = useState(null);
    const [showApiTest, setShowApiTest] = useState(false);

    const handleLevelComplete = (completedLevelIndex) => {
        const justCompletedLevel = completedLevelIndex + 1;

        setCompletedLevel(justCompletedLevel);
        setShowLevelComplete(true);

        setTimeout(() => {
            setShowLevelComplete(false);
        }, 2800);
    };

    // Footer link handler
    const handleFooterLinkClick = (linkType) => {
        if (linkType === 'privacy') {
            setShowPrivacyPolicy(true);
        }
    };
    
    useEffect(() => {
        // Initialize version checking service
        versionService.initialize();

        // Force check for new day on initial load
        checkForNewDay(true);

        // Check if this is the user's first time playing
        const hasPlayedBefore = gameService.hasPlayedBefore();
        if (!hasPlayedBefore) {
            setShowHowToPlay(true);
            gameService.markAsPlayed();
        }

        // Show API test panel in development mode
        if (import.meta.env.DEV) {
            setShowApiTest(true);
        }

        // Set up interval to check for date change
        const dateCheckInterval = setInterval(() => {
            checkForNewDay();
        }, 5 * 60 * 1000); // Check every 5 minutes

        // Check for a new day when the app becomes visible
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                checkForNewDay();
            }
        });

        return () => {
            clearInterval(dateCheckInterval);
            document.removeEventListener('visibilitychange', () => { });
        };
    }, [checkForNewDay]);

    // Show loading state
    if (isLoading) {
        return (
            <div className="app-container">
                <div className="loading-indicator">
                    <div className="spinner"></div>
                    <p>Loading game...</p>
                </div>
            </div>
        );
    }

    // Show error state
    if (error) {
        return (
            <div className="app-container">
                <div className="error-message">
                    <h2>Error Loading Game</h2>
                    <p>{error}</p>
                    <button
                        onClick={() => window.location.reload()}
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                    >
                        Reload App
                    </button>
                </div>
            </div>
        );
    }

    const currentLevel = gameState?.currentLevel + 1 || 1;

    return (
        <div className={`theme-standard theme-level-${currentLevel}`}>
            <div className="app-container">
                <div className="star-layer">
                    <div className="star-layer__small"></div>
                    <div className="star-layer__medium"></div>
                </div>

                {/* Header with logo and controls */}
                <AppHeader 
                    onOpenHowToPlay={() => setShowHowToPlay(true)} 
                    onOpenFeedback={() => setShowFeedback(true)}
                />

                {/* Modals */}
                <HowToPlayModal
                    isOpen={showHowToPlay}
                    onClose={() => setShowHowToPlay(false)}
                    currentMode="normal"
                />

                <PrivacyPolicyModal
                    isOpen={showPrivacyPolicy}
                    onClose={() => setShowPrivacyPolicy(false)}
                />
                
                <LeaveFeedbackModal
                    isOpen={showFeedback}
                    onClose={() => setShowFeedback(false)}
                />

                {showLevelComplete && gameState && (
                    <LevelCompleteOverlay
                        level={completedLevel}
                        onComplete={() => setShowLevelComplete(false)}
                        message={GAME_CONFIG.levelMessages[completedLevel]}
                    />
                )}

                <main className="app-main">
                    {gameState && (
                        <Game
                            onLevelComplete={handleLevelComplete}
                            gameState={gameState}
                        />
                    )}

                    {/* API Test Panel for development */}
                    {/* {showApiTest && <ApiTestPanel />} */}
                </main>

                {/* Footer with support options */}
                <AppFooter onLinkClick={handleFooterLinkClick} />
            </div>
        </div>
    );
}

// Main App component with providers
function App() {
    return (
        <GameProvider>
            <AppContent />
        </GameProvider>
    );
}

export default App;
