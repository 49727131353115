// src/components/modals/BaseModal.jsx
import React, { useEffect, useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

/**
 * BaseModal - Reusable modal component with standard functionality
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to call when modal is closed
 * @param {string} props.title - Modal title
 * @param {React.ReactNode} props.children - Modal content
 * @param {string} props.className - Additional class names for the modal
 * @param {boolean} props.hideCloseButton - Whether to hide the close button
 * @param {string} props.size - Modal size ('sm', 'md', 'lg')
 * @param {boolean} props.preventBackdropClose - Prevent closing when clicking the backdrop
 * @returns {React.ReactElement|null}
 */
const BaseModal = ({
    isOpen,
    onClose,
    title,
    children,
    className = '',
    hideCloseButton = false,
    size = 'md',
    preventBackdropClose = false,
}) => {
    const modalRef = useRef(null);

    // Handle escape key to close modal
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape' && !preventBackdropClose) onClose();
        };

        if (isOpen) {
            window.addEventListener('keydown', handleEscape);
        }

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [isOpen, onClose, preventBackdropClose]);

    // Prevent scrolling of the background when modal is open
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    // Handle overlay click to close
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget && !preventBackdropClose) {
            onClose();
        }
    };

    // Get size class
    const sizeClass = 
        size === 'sm' ? 'modal--sm' :
        size === 'lg' ? 'modal--lg' :
        'modal--md';

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div
                className={`modal ${sizeClass} ${className}`}
                ref={modalRef}
            >
                <div className="modal-header">
                    {title && <h2 className="modal-title mb-0">{title}</h2>}
                    {!hideCloseButton && (
                        <button
                            onClick={onClose}
                            className="modal-close-button"
                            aria-label="Close modal"
                        >
                            <XMarkIcon className="modal-close-icon" />
                        </button>
                    )}
                </div>

                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default BaseModal;
