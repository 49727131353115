// src/components/modals/LeaveFeedbackModal.jsx
import React, { useState } from 'react';
import BaseModal from './BaseModal';
import { sendFeedbackEmail } from '../../services/emailService';

/**
 * LeaveFeedbackModal - Modal for collecting user feedback
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to call when modal is closed
 * @returns {React.ReactElement|null}
 */
const LeaveFeedbackModal = ({ isOpen, onClose }) => {
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!feedback.trim()) {
            setSubmitStatus({
                type: 'error',
                message: 'Please enter some feedback before submitting'
            });
            return;
        }
        
        setIsSubmitting(true);
        setSubmitStatus({ type: '', message: '' });
        
        try {
            // Send the email using our email service
            await sendFeedbackEmail({
                feedback,
            });
            
            setSubmitStatus({
                type: 'success',
                message: 'Thank you for your feedback! We appreciate your input.'
            });
            
            // Clear the form
            setFeedback('');
            
            // Close the modal after a delay
            setTimeout(() => {
                onClose();
                setSubmitStatus({ type: '', message: '' });
            }, 3000);
            
        } catch (error) {
            console.error('Error sending feedback:', error);
            setSubmitStatus({
                type: 'error',
                message: 'Failed to send feedback. Please try again later.'
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            title="Share Your Feedback"
            size="md"
        >
            <div className="feedback-form-container">
                <p className="feedback-description mb-4 text-gray-300">
                    Leave us feedback with ideas on how to improve the game, or let us know if something isn't working for you!
                </p>
                
                <form onSubmit={handleSubmit} className="feedback-form">
                    <div className="mb-4">
                        <label htmlFor="feedback" className="hidden block text-gray-300 mb-2 font-medium" aria-label="Your Feedback">
                            Your Feedback
                        </label>
                        <textarea
                            id="feedback"
                            className="w-full p-3 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400"
                            rows="5"
                            placeholder="Tell us what you think..."
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    
                    {submitStatus.message && (
                        <div className={`mb-4 p-3 rounded-lg ${
                            submitStatus.type === 'error' 
                                ? 'bg-red-900/50 border border-red-600 text-red-200' 
                                : 'bg-green-900/50 border border-green-600 text-green-200'
                        }`}>
                            {submitStatus.message}
                        </div>
                    )}
                    
                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="mr-2 px-4 py-2 text-gray-300 hover:text-white transition-colors"
                            onClick={onClose}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-primary-400 hover:bg-primary-200 text-white rounded-lg transition-colors"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Sending...' : 'Submit Feedback'}
                        </button>
                    </div>
                </form>
            </div>
        </BaseModal>
    );
};

export default LeaveFeedbackModal;
