// src/components/game/LetterGrid.jsx - Updated with defensive coding
import React from 'react';
import { getLetterMatches } from '../../utils/game/wordUtils';
import { GAME_CONFIG } from '../../config/gameConfig';

const LetterGrid = ({ targetWord, guesses, userInput = '' }) => {
    // Guard against undefined targetWord
    if (!targetWord) {
        console.warn('LetterGrid received undefined targetWord');
        return null;
    }
    
    // Initialize empty grid
    const emptyGrid = Array(targetWord.length).fill({
        letter: '',
        isExactMatch: false,
        isInWord: false,
        isUserInput: false
    });
    
    // Get information from last guess if available
    let greenPositions = new Map();
    let yellowPositions = new Map();
    
    if (guesses && guesses.length > 0) {
        const lastGuess = guesses[guesses.length - 1];
        const matches = getLetterMatches(lastGuess, targetWord);
        
        matches.forEach(match => {
            if (match.isExactMatch) {
                greenPositions.set(match.index, match.letter);
            } else if (match.isInWord) {
                yellowPositions.set(match.index, match.letter);
            }
        });
    }
    
    // Build the current grid state
    const gridState = [...emptyGrid];
    
    // First, fill in green letters (exact matches from last guess)
    greenPositions.forEach((letter, position) => {
        gridState[position] = {
            letter,
            isExactMatch: true,
            isInWord: false,
            isUserInput: false
        };
    });
    
    // Find positions that need user input (non-green positions)
    const editablePositions = [];
    for (let i = 0; i < gridState.length; i++) {
        if (!greenPositions.has(i)) {
            editablePositions.push(i);
        }
    }
    
    // Fill in user input into editable positions
    const userInputArray = userInput ? userInput.split('') : [];
    for (let i = 0; i < Math.min(userInputArray.length, editablePositions.length); i++) {
        const position = editablePositions[i];
        
        gridState[position] = {
            letter: userInputArray[i],
            isExactMatch: false,
            isInWord: yellowPositions.has(position),
            isUserInput: true
        };
    }
    
    return (
        <div className="letter-grid">
            <div className="letter-grid__container">
                {gridState.map((state, index) => (
                    <div
                        key={index}
                        className={`letter-grid__box ${
                            state.isExactMatch ? 'letter-grid__box--filled letter-grid__box--pop' :
                            (GAME_CONFIG.enableEasyMode && state.isInWord && !state.isUserInput) ? 'letter-grid__box--in-word' :
                            state.letter ? 'letter-grid__box--typing' : ''
                        }`}
                    >
                        {state.letter}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LetterGrid;
