// src/contexts/GameContext.jsx
import React, { createContext, useContext, useReducer, useEffect, useState } from 'react';
import { gameService } from '../services/gameService';
import { statsService } from '../services/statsService';
import timeService from '../services/timeService';
import apiService from '../services/apiService';
import storageService from '../services/storageService';
import { GAME_CONFIG } from '../config'; // Assuming config is consolidated

// Create the context
const GameContext = createContext(null);

// Storage key constants
const CURRENT_DATE_KEY = 'wordcrown_current_date';
const PUZZLE_DATE_KEY = 'wordcrown_puzzle_date';
const GAME_STATE_KEY = 'wordcrown_game_state';

// Initial state
const initialState = {
    gameState: null,
    isLoading: true,
    error: null,
};

// Game reducer for handling state updates
function gameReducer(state, action) {
    switch (action.type) {
        case 'LOAD_GAME_START':
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case 'LOAD_GAME_SUCCESS':
            return {
                ...state,
                gameState: action.payload,
                isLoading: false,
                error: null,
            };
        case 'LOAD_GAME_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case 'UPDATE_GAME_STATE':
            return {
                ...state,
                gameState: action.payload,
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        case 'CLEAR_ERROR':
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

// Provider component that wraps the app
export function GameProvider({ children }) {
    const [state, dispatch] = useReducer(gameReducer, initialState);
    const [dailyStats, setDailyStats] = useState(null);

    // Initialize game state on component mount
    useEffect(() => {
        loadGameState();

        // Optional: Load daily stats if needed
        loadDailyStats();
    }, []);

    // Load the game state
    const loadGameState = () => {
        try {
            dispatch({ type: 'LOAD_GAME_START' });
            const loadedState = gameService.getDailyState();
            dispatch({ type: 'LOAD_GAME_SUCCESS', payload: loadedState });
        } catch (error) {
            console.error('Error loading game state:', error);
            dispatch({ type: 'LOAD_GAME_ERROR', payload: error.message });
        }
    };

    // Load daily stats
    const loadDailyStats = async () => {
        try {
            if (gameService.apiEnabled) {
                const stats = await apiService.getDailyStats();
                setDailyStats(stats);
            } else {
                const stats = statsService.getDailyStats();
                setDailyStats(stats);
            }
        } catch (error) {
            console.error('Error loading daily stats:', error);
            // Fallback to local stats
            const stats = statsService.getDailyStats();
            setDailyStats(stats);
        }
    };

    // Check for a new day
    const checkForNewDay = (isInitialLoad = false) => {
        const now = new Date();
        const currentDate = timeService.getEasternTimeDate(now);
        const storedDate = storageService.getItem(CURRENT_DATE_KEY);

        // Get the current puzzle to double-check if it's changed
        const currentPuzzle = GAME_CONFIG.getPuzzleForDate(now);
        const storedPuzzleDate = storageService.getItem(PUZZLE_DATE_KEY);

        // console.log('Checking for new day:', {
        //     storedDate,
        //     currentDate,
        //     storedPuzzleDate,
        //     currentPuzzleDate: currentPuzzle.date,
        //     isNewDay: storedDate !== currentDate || storedPuzzleDate !== currentPuzzle.date
        // });

        // If it's a new day OR the puzzle has changed OR it's the initial load
        if (isInitialLoad || !storedDate || storedDate !== currentDate || storedPuzzleDate !== currentPuzzle.date) {
            // Store the new date and puzzle date
            storageService.setItem(CURRENT_DATE_KEY, currentDate);
            storageService.setItem(PUZZLE_DATE_KEY, currentPuzzle.date);

            if (!isInitialLoad) {
                // Only reload game state if this isn't the initial check
                loadGameState();
            }

            return true; // Day changed
        }

        return false; // Day did not change
    };

    // Update the game state
    const updateGameState = async (levelIndex, guesses, isComplete, countMiss = false) => {
        try {
            const newState = await gameService.saveGameState(
                levelIndex,
                guesses,
                isComplete,
                countMiss
            );

            if (newState) {
                dispatch({ type: 'UPDATE_GAME_STATE', payload: newState });

                // If the game is over, refresh daily stats
                if (newState.isGameOver) {
                    await loadDailyStats();
                }

                return newState;
            } else {
                throw new Error('Failed to update game state');
            }
        } catch (error) {
            console.error('Error updating game state:', error);
            dispatch({ type: 'SET_ERROR', payload: error.message });
            throw error;
        }
    };

    // Handle level completion
    const completeLevel = async (levelIndex, guesses) => {
        return await updateGameState(levelIndex, guesses, true, false);
    };

    // Handle level failure
    const failLevel = async (levelIndex, guesses) => {
        return await updateGameState(levelIndex, guesses, false, true);
    };

    // Check if the user has played before
    const hasPlayedBefore = () => {
        return gameService.hasPlayedBefore();
    };

    // Mark the user as having played
    const markAsPlayed = () => {
        gameService.markAsPlayed();
    };

    // Reset the game (useful for debugging)
    const resetGame = () => {
        storageService.removeItem(GAME_STATE_KEY);
        storageService.removeItem(CURRENT_DATE_KEY);
        storageService.removeItem(PUZZLE_DATE_KEY);
        loadGameState();
    };

    // Create the context value object with state and functions
    const value = {
        ...state,
        dailyStats,
        updateGameState,
        completeLevel,
        failLevel,
        checkForNewDay,
        loadGameState,
        hasPlayedBefore,
        markAsPlayed,
        resetGame,
    };

    return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
}

// Custom hook for accessing the game context
export const useGame = () => {
    const context = useContext(GameContext);
    if (context === null) {
        throw new Error('useGame must be used within a GameProvider');
    }
    return context;
};
