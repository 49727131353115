// src/components/game/Game.jsx
import React, { useEffect } from 'react';
import { GameBoard, GameControls, LevelProgress } from '.';
import { CompletionModal } from '../modals';
import { MessageDisplay } from '../feedback';
import { useGame } from '../../contexts/GameContext';
import useGameInput from '../../hooks/game/useGameInput';
import useKeyboardInput from '../../hooks/game/useKeyboardInput';

/**
 * Main Game Component
 * Orchestrates the game experience by composing smaller, focused components
 */
function Game({ onLevelComplete = () => {} }) {
    // Get the game state from context
    const { gameState } = useGame();
    
    // Use our custom hook for all the game input logic
    const {
        userInput,
        message,
        messageType,
        isValidating,
        showCompletionModal,
        setShowCompletionModal,
        handleKeyPress,
        handleBackspace,
        handleSubmit,
        chainRef
    } = useGameInput(onLevelComplete);
    
    // Use the keyboard input hook to handle physical keyboard events
    useKeyboardInput({
        onKeyPress: handleKeyPress,
        onBackspace: handleBackspace,
        onSubmit: handleSubmit,
        isDisabled: isValidating || !gameState || gameState.isGameOver || 
                    (gameState?.levels[gameState.currentLevel]?.isComplete)
    });

    // Always show completion modal if game is over, both on initial load and when state changes
    useEffect(() => {
        if (gameState?.isGameOver) {
            setShowCompletionModal(true);
        }
    }, [gameState, setShowCompletionModal]);

    // Guard clause for no game state
    if (!gameState) return null;

    // Get current level data for easy access
    const currentLevelIndex = gameState.currentLevel;
    const currentLevel = gameState.levels[currentLevelIndex];
    
    return (
        <div className="game-container">
            {/* Game Controls - Status indicators, feedback, and controls */}
            <GameControls 
                currentLevel={currentLevel}
                chainRef={chainRef}
            />

            {/* Main Game Board - Word guessing interface */}
            <GameBoard
                currentLevel={currentLevel}
                userInput={userInput}
                handleKeyPress={handleKeyPress}
                handleBackspace={handleBackspace}
                handleSubmit={handleSubmit}
                isGameOver={gameState.isGameOver}
                currentLevelIndex={currentLevelIndex}
            />

            {/* Message to the user */}
            <MessageDisplay 
                message={message}
                messageType={messageType}
            />

            {/* Level progress UI */}
            <LevelProgress 
                levels={gameState.levels} 
                currentLevel={currentLevelIndex} 
            />

            {/* Completion modal */}
            <CompletionModal
                isOpen={showCompletionModal}
                onClose={() => setShowCompletionModal(false)}
                gameState={gameState}
            />
        </div>
    );
}

export default Game;
